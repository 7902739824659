@import './variables';

.ant-select {
  .ant-select-selector {
    .ant-select-selection-item {
      overflow-wrap: break-word !important;
      white-space: normal;
      text-overflow: clip;
      @media (max-width: $xxl) {
        overflow-wrap: normal !important;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}

.ant-select-item {
  .ant-select-item-option-content {
    overflow-wrap: break-word !important;
    white-space: normal;
    text-overflow: clip;
    font-family: 'Roboto Condensed', sans-serif;
  }
}

.ant-select-item-option-selected {
  font-weight: $font-weight-regular !important;
}

.ant-table {
  border: none !important;
  .ant-table-container {
    border: none !important;
    .ant-table-content {
      table {
        thead {
          tr {
            th {
              background-color: $white !important;
              border: none !important;
              border-bottom: 1px solid $gray !important;
              color: $font-gray;
              font-family: 'Roboto', sans-serif;
              font-size: 11px;
              font-weight: $font-weight-medium;
              text-align: left;
              text-transform: uppercase;
            }
          }
        }
        tbody {
          border: none !important;
          tr {
            border-top: 1px solid $gray;
            border-top-color: $gray !important;
            border-bottom: 1px solid $gray;
            td {
              text-align: right;
              border-left: none !important;
              color: $font-black;
              font-family: 'Roboto', sans-serif;
              font-weight: $font-weight-medium;
              font-size: 13px;
              &:first-of-type {
                text-align: left;
                font-weight: $font-weight-bold;
              }
              &:last-of-type {
                border-right: none !important;
              }
            }
          }
        }
        thead tr th,
        tbody tr td {
          padding: 10px;
        }
      }
    }
  }
}
