@import '../../shared/styles/variables';

.home-container {
  .home-header {
    padding-bottom: 40px;
    h1 {
      font-weight: $font-weight-bold;
      font-size: 28px;
      color: $font-black;
      text-transform: uppercase;
      margin: 0 0;
      padding: 0 0;
    }
  }
}

.title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 10px;

  h1 {
    margin: 0;
  }

  .downloadXlsxButton,
  .downloadXlsxButton:hover,
  .downloadXlsxButton:focus,
  .downloadXlsxButton:active {
    margin-left: 10px;
    background-color: $light-green;
    border-color: $light-green;

    .anticon {
      svg {
        fill: $white;
      }
    }
  }

  .downloadXlsxButton:hover {
    opacity: 0.5;
  }

  .downloadXlsxButton:disabled {
    .anticon {
      svg {
        fill: #bfb7b7;
      }
    }
  }
}

.buttons {
  justify-content: right;
  display: flex !important;
}
