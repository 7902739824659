@import '../../shared/styles/variables';

.table-container {
  display: flex;
  width: 100%;
  height: auto;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  .ant-table-wrapper {
    width: 100%;

    @media screen and (min-width: $xxl) {
      width: 50%;
    }
  }
}
