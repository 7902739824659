@import '../../../../shared/styles/variables';
.page-header {
  background-color: $white;
  margin: 0 auto;
  max-width: $max-1800;
  width: 100%;
  .sider-controller {
    width: 44px;
    margin-right: 16px;
    height: 100%;
    display: flex;
    align-items: center;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      svg {
        width: 18px;
        height: 18px;
      }
    }
    &:hover {
      color: $light-green;
    }
  }
  .year-select {
    font-family: 'Roboto Condensed', sans-serif !important;
    font-size: 13px;
    font-weight: $font-weight-bold;
    line-height: 25px;
    .ant-select {
      .ant-select-selector {
        border: none;
        background-color: $light-gray;
        .ant-select-selection-search,
        .ant-select-selection-placeholder {
          line-height: 25px;
        }
      }
    }
  }
  .ant-select {
    &.nav-select {
      width: 100%;
      .ant-select-selector {
        border-radius: 0;
        border: none;
        font-weight: $font-weight-regular;
        background-color: $light-gray;
      }
    }
  }
  .home-button {
    width: 44px;
    height: 100%;
    margin-right: 16px;
    border-bottom: solid 2px $light-gray;
    button {
      width: inherit;
      height: inherit;
      align-items: center;
      border-radius: 0;
      border-style: none;
      background-color: $light-gray;
      color: $font-gray;
      &:focus {
        span {
          color: $light-green;
        }
      }
      &:hover {
        span {
          color: $light-green;
        }
      }
    }
    .ant-btn {
      &:hover {
        color: $light-green;
      }
      &:focus {
        color: $light-green;
      }
    }
    &.active {
      border-bottom: solid 2px $light-green;
      button {
        span {
          color: $light-green;
        }
      }
    }
  }
  .top-part {
    display: flex;
    flex-direction: row;
    height: 25px;
    margin-bottom: 16px;
    .ant-space-align-center {
      height: 100%;
      width: calc(100% - 60px);
    }
  }

  .report-request-button,
  .report-request-button:hover,
  .report-request-button:focus,
  .report-request-button:active {
    margin-left: 10px;
    background-color: #fa9c5d;
    border-color: #fa9c5d;
    height: 32px;
    padding-left: 15px;
    padding-right: 15px;

    .anticon {
      svg {
        fill: $white;
      }
    }
  }

  .report-request-button:hover {
    opacity: 0.5;
  }

  .level-select {
    display: flex;
    flex-direction: row;
    height: 60px;
  }
  .selection-row-container {
    height: 100%;
    width: calc(100% - 60px);
    .ant-row {
      height: 100%;
      .ant-col {
        height: 100%;
        padding-bottom: 0;
        min-height: 60px;
      }
    }
    .selection-container {
      position: relative;
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 60px;
      max-width: 100%;
      .selection-button {
        height: 100%;
        border: none;
        background-color: $light-gray;
        border-left: solid 1px $gray;
        width: 40px;
        span {
          color: $font-gray;
        }
      }
      span {
        &.label {
          color: $font-gray;
          position: absolute;
          display: block;
          left: 11px;
          top: 11px;
          line-height: 10px;
          z-index: 2;
          pointer-events: none;
          font-size: 10px;
          font-weight: $font-weight-medium;
          letter-spacing: 2px;
          text-transform: uppercase;
          font-family: 'Roboto Condensed', sans-serif !important;
        }
      }
      .ant-select {
        height: 100%;
        color: $font-gray;
        max-width: calc(100% - 40px);
        font-family: 'Roboto Condensed', sans-serif !important;
        font-size: 13px;
        line-height: 25px;

        &.active {
          .ant-select-selector {
            border-bottom: solid 2px $light-green;
            color: $font-black;
            font-weight: $font-weight-bold;
          }
        }
        &.label-bold {
          .ant-select-selector {
            .ant-select-selection-item {
              height: 30px;
              font-weight: $font-weight-bold !important;
              line-height: 13px;
              color: $font-black;
              display: flex;
              align-items: center;
              white-space: pre-wrap;
            }
          }
        }
        .ant-select-selector {
          border-bottom: solid 2px $light-gray;
          font-weight: $font-weight-bold;
          height: 100%;
          justify-content: flex-start;
          align-items: flex-end;
          .ant-select-selection-search,
          .ant-select-selection-item,
          .ant-select-selection-placeholder {
            position: absolute;
            top: inherit;
            bottom: 5px;
            line-height: 25px;
            color: $font-gray;
            input {
              height: 25px;
            }
          }
          .ant-select-selection-item {
            color: $font-black;
          }
        }
      }
    }
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 25px;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 25px;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 11px;
  }
}

.report-request-form-container {
  max-width: 500px;

  .ant-btn,
  .ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active {
    background-color: $light-green;
    border-color: $light-green;
    height: 32px;
  }

  .ant-btn:hover {
    opacity: 0.5;
  }

  .ant-spin-dot-item {
    background-color: #fff;
  }

  p {
    text-align: justify;
    text-justify: inter-word;
  }
}
