@import '../../styles/variables';

.chart-container {
  padding-bottom: 30px;
  .ant-card {
    .ant-card-head {
      background-color: $light-gray;
      display: flex;
      .ant-card-head-wrapper {
        flex: 1;
        padding: 12px 0;
        .ant-card-head-title {
          text-align: left;
          font-size: 13px;
          font-family: 'Poppins', sans-serif;
          font-weight: $font-weight-bold;
          color: $font-black;
          padding: 0 24px 0 0;
          white-space: normal;
          display: flex;
          flex-direction: row;
          align-items: center;
          align-content: center;
          justify-content: flex-start;
          flex-wrap: nowrap;
          gap: 10px;
          > p,
          span {
            margin-bottom: 0px;
          }
        }
        .ant-card-extra {
          padding: 0;
        }
      }
    }

    .ant-card-body {
      min-height: 505px;
      flex-direction: column;
      padding: 15px 30px;
    }
  }
  .ant-switch-checked {
    background-color: #0aba9d;
  }

  .ant-card {
    border-radius: 0;
    border-color: $gray;
    .ant-card-body {
      display: flex;
    }
  }

  .ant-row {
    width: 100%;
    flex-grow: 1;
  }

  .ant-col {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .toggleContainer {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    span {
      margin-right: 10px;
      font-size: 12px;
      color: $font-card-gray;
      font-weight: $font-weight-medium;
    }
  }

  .plot__fullsize {
    width: 100%;
    height: 450px;

    .plot-container {
      display: flex;
      justify-content: center;
    }
  }

  .table-container {
    width: 100%;
    margin-top: 20px;
  }
}
