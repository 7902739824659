@import '../../../shared/styles/variables';
.mainHeader {
  background: #fff;
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 130px;
  box-shadow: 0 15px 20px -15px rgba(0, 0, 0, 0.1);
  padding: 15px 16px 0;
  line-height: 1.4 !important;
}

.mainContent {
  background-color: $white;
  padding: 10px 16px 0;
  margin-top: 130px;
}

.layout-content-container {
  padding-top: 50px;
}
