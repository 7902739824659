@import '../../styles/variables';

.pageTitle {
  font-size: 28px;
  font-weight: $font-weight-regular;

  .anticon {
    margin-left: 10px
  }
}

.pageTitle__bold {
  font-weight: $font-weight-bold;
  text-transform: uppercase;
}
