@import '../../../shared/styles/variables';

h2 {
  margin-bottom: 0 !important;
}

.details_btn {
  border-radius: 3px !important;
}

.downloadPDFButton,
.downloadPDFButton:hover,
.downloadPDFButton:focus,
.downloadPDFButton:active {
  background-color: $light-green !important;
  border-color: $light-green !important;
  margin: 10px 0;

  .anticon {
    svg {
      fill: $white;
    }
  }
}

.downloadPDFButton:hover {
  opacity: 0.5;
}

.downloadPDFButton:disabled {
  .anticon {
    svg {
      fill: #bfb7b7;
    }
  }
}

.divider {
  margin: 0 0 8px 0 !important;
}

h3 {
  font: normal normal 600 11px/17px Poppins;
  letter-spacing: 0px;
  color: #9a9a9a !important;
  opacity: 1;
  font-size: 11px;
  font-weight: $font-weight-bold;
}

.indicatorInfo {
  div > span,
  p {
    font: normal normal normal 15px/18px Roboto;
    letter-spacing: 0px;
    color: #111111;
    opacity: 1;
    text-align: justify;
    margin-bottom: 8px;
  }
}

#formula {
  background: #f2f2f2 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 100%;
  line-height: 40px !important;
  text-align: center;
}

.side-drawer {
  .indicatorInfo {
    .sider-stretch {
      div {
        &.info {
          display: flex;
          flex-direction: row;
          align-items: center;
          align-content: center;
          justify-content: flex-start;
          flex-wrap: nowrap;
          column-gap: 10px;
          > p {
            overflow-wrap: break-word;
            color: #1890ff;
          }
        }
      }
      .sider-top {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        align-items: center;
        align-content: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
        .warning {
        }
      }
    }
  }
}
