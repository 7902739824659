// colors
$font-gray: #9a9a9a;
$font-black: #111111;
$font-card-gray: #8a8a8a;
$light-gray: #f8f8f8;
$border-gray: #f2f2f2;
$light-green: #0aba9d;
$ant-danger: #d52626;
$white: #ffffff;
$gray: #ececec;
// font sizes
$font-weight-bold: 700;
$font-weight-medium: 500;
$font-weight-regular: 400;
$font-weight-light: 300;
// min res brakepoints for antd
$xs: 480px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1600px;

// custom styles
.max-1800 {
  max-width: 1800px;
  margin: 0 auto;
}

$max-1800: 1800px;
