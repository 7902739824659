@import './shared/styles/variables';

.config-dropdown {
  font-family: 'Roboto Condensed', sans-serif !important;
  border: solid 2px $gray;
  border-radius: 0;
  position: fixed;
  .ant-select-item-option {
    background-color: $light-gray;
  }
  .ant-select-item-option-active {
    background-color: $light-green !important;
    color: $white;
    border-color: $light-green;
  }
}

.side-drawer {
  .sider-stretch {
    width: 100%;
    height: 100%;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
    margin: 0;
  }
  .side-drawer-content-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    h2 {
      margin-bottom: 25px;
      font-weight: $font-weight-bold;
      font-size: 20px;
      text-transform: uppercase;
    }
    h3 {
      font: normal normal bold 11px/17px Poppins;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
      color: #111111 !important;
    }
    .territory-info {
      p {
        text-align: left;
        font: normal normal medium 11px/17px Poppins;
        letter-spacing: 0px;
        color: #9a9a9a;
        opacity: 1;
        margin-bottom: 0;
      }
      span {
        text-align: left;
        font: normal normal normal 15px/18px Roboto;
        letter-spacing: 0px;
        color: #111111;
        opacity: 1;
      }
      div {
        margin-bottom: 5px;
      }
    }
    .sider-top {
      width: 100%;
      display: flex;
      flex-direction: row;
      button {
        margin-left: auto;
        border: solid 0;
        background-color: $light-gray;
      }
    }
    .select-group {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      margin-bottom: 30px;
      .ant-select {
        border: 2px solid $border-gray;
        border-radius: 0;
        font-family: 'Roboto Condensed', sans-serif;
      }
      .ant-select-focused {
        .ant-select-selector {
          border-color: $light-green;
          box-shadow: none;
        }
      }
      .ant-select-selector {
        &:hover {
          border-color: $light-green;
        }
      }
      &.selected {
        .ant-select {
          border: solid 2px $light-green;
          border-radius: 0;
        }
      }
    }
    .sider-controls {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      button {
        height: 56px;
        border: 0 solid;
        border-radius: 0;
        font-size: 15px;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        &:nth-child(1) {
          margin-bottom: 10px;
        }
        &.controls-accept {
          background: $light-green;
          background-color: $light-green;
          color: $white;
          &:hover {
            color: $white;
          }
        }
        &.controls-cancel {
          background: $light-gray;
          background-color: $light-gray;
        }
      }
    }
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 0;
    border: none;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 46px;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 46px;
  }

  .ant-select {
    font-size: 15px;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 46px;
  }

  .ant-select-multiple .ant-select-selector {
    min-height: 46px;
  }
}
