@import '../../../../shared/styles/variables';
.mode-select-col {
  cursor: pointer;
  span {
    padding: 0 0 8px;
    color: $font-black;
    user-select: none;
    font-size: 15px;
    font-weight: $font-weight-medium;
    opacity: 0.4;
    &:hover {
      opacity: 1;
    }
    &.active {
      border-bottom: solid 2px $light-green;
      opacity: 1;
    }
  }
}
