@import '../../shared/styles/variables';

.table-container {
  display: flex;
  width: 100%;
  height: auto;
  flex-wrap: nowrap;
  font-weight: $font-weight-medium;
  font-family: 'Roboto', sans-serif;

  .ant-table {
    color: $font-black;
    font-size: 13px;
    line-height: 1.4;
  }

  .ant-table-header {
    font-size: 11px;
    text-transform: uppercase;
  }

  .ant-table-thead > tr > th {
    background: none;
    color: $font-gray;
  }

  .ant-table-tbody > tr > td {
    &:first-of-type {
      font-weight: $font-weight-bold;
    }
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 10px;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $gray;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $font-gray;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $font-card-gray;
  }
}
